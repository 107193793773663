import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  return (
    <>
      <MainNavbar />
      
      <div style={{ paddingTop: '10rem', paddingBottom: '0rem', minHeight: '100vh' }}>
        <Outlet />
      </div>

      <MainFooter />      
    </>
  );
}
