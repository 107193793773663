import { Icon } from '@iconify/react';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
//
import Logo from '../../components/Logo';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'none'
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 1 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 1, mb: { xs: 0, md: 0 } }}
            >
              <IconButton onClick={(event) => window.open('https://t.me/shazu_io', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                <TelegramIcon />
              </IconButton>

              <IconButton onClick={(event) => window.open('https://twitter.com/shazu_io', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                <TwitterIcon />
              </IconButton>

              <IconButton onClick={(event) => window.open('https://discord.com/invite/shazu', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                <img src="/static/SHAZU/icons/discord.svg" style={{ width: '1em' }} alt="" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 1,
            pb: 2,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2022. SHAZU. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
