import { useForm } from 'react-hook-form';

// material
import { Container, Typography, Box, Paper, Button, TextField, styled, Stack } from '@mui/material';
import { motion } from 'framer-motion';

// hooks
import useSettings from '../hooks/useSettings';

// components
import { varWrapEnter, varFadeInUp } from '../components/animate';
import Page from '../components/Page';

const Item = styled(Paper)(({ theme }) => ({
...theme.typography.body2,
padding: theme.spacing(2),
color: theme.palette.text.primary,
}));

const AnimateContainer = styled(motion.div)(({ theme }) => ({}));

// ----------------------------------------------------------------------

export default function BetaPage(props) {
const { themeStretch } = useSettings();

const { register, handleSubmit, watch, formState: { errors } } = useForm();

const handleAccess = (data) => {
    if(data.password === 'simSuc-fubqun-8pyscy'){
        props.onChangeAccess(true);
    }else{
        alert("Incorrect password!");
        props.onChangeAccess(false);
    }
}; 

return (
    <Page title="SHAZU | Beta Access">
        <Container maxWidth={themeStretch ? false : 'xl'}>
            <AnimateContainer initial="initial" animate="animate" variants={varWrapEnter} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', width: '100%' }} >
                <motion.div variants={varFadeInUp} style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>                    
                    <div className="p-relative w-100" style={{ height: '0', display: 'flex', justifyContent: 'center'}}>
                        <img src="/static/SHAZU/abstract/flower-spin.svg" alt="" className="rotating-30" style={{ position: 'absolute', width: '10rem', top: '-3rem', opacity: '0.1', zIndex: '-1' }} />
                    </div>

                    <Stack className="elevate-card" justifyContent="center" alignItems="center" style={{ textAlign: 'center', width: 'fit-content', padding: '2rem 1rem 2rem 1rem' }}>
                        <img src="/static/SHAZU/ShazuTextLogo.svg" alt="SHAZU" style={{ width: '10rem', marginBottom: '-0.3rem' }} />
                        <Typography variant="h4" component="h1" paragraph>
                            Beta Access
                        </Typography>
                    
                        <Box style={{ maxWidth: '25rem' }}>
                            <Typography variant='body1' style={{ marginTop: '-1rem', marginBottom: '2rem', opacity: '0.6' }}>Input the password to access this beta feature.</Typography>
                            
                            <form onSubmit={handleSubmit(handleAccess)}>
                                <TextField fullWidth error={Boolean(errors.password)} color="yellow" name="password" type="password" label="Password" {...register("password", { required: true })} />
                                {errors.password && <Typography color='error' style={{ display: 'block', marginTop: '0.2rem' }}>Fill this field first!</Typography>}

                                <Button variant="contained" color="yellow" type="submit" style={{ marginTop: '2rem', width:'100%' }} >Access</Button>
                            </form>
                        </Box>
                    </Stack>
                </motion.div>
            </AnimateContainer>
        </Container>
    </Page>
);
}
