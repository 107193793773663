import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import pMinDelay from 'p-min-delay';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------
const Loadable = (Component) =>  (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...({
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {


  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Live
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Navigate to="/story" replace />},
        { path: 'story', element: <StoryPage /> },
        { path: 'token', element: <TokenPage /> },
        { path: 'airdrop', element: <AirdropPage /> },
        { path: 'dojo', element: <DojoPage /> },
        { path: 'mynft', element: <NFTPage /> },
        { path: 'game', element: <GamePage />},
        { 
          path: 'marketplace', 
          children: [
            { element: <MarketplacePage /> },
            { path: 'detail/:nftId', element: <ListingDetailPage />}
          ]

        },
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}


// IMPORT COMPONENTS
const StoryPage = Loadable(lazy(() => 
  pMinDelay(import('../pages/StoryPage'), 1000)
));

const TokenPage = Loadable(lazy(() =>
  pMinDelay(import('../pages/TokenPage'), 1000)
))

const AirdropPage = Loadable(lazy(() =>
  pMinDelay(import('../pages/AirdropPage'), 1000)
))

const DojoPage = Loadable(lazy(() =>
  pMinDelay(import('../pages/DojoPage'), 1000)
))

const NFTPage = Loadable(lazy(() =>
  pMinDelay(import('../pages/NFTPage'), 1000)
))

const GamePage = Loadable(lazy(() => 
  pMinDelay(import('../pages/GamePage'), 1000)
))

const MarketplacePage = Loadable(lazy(() => 
  pMinDelay(import('../pages/marketplace/MarketplacePage'), 1000)
))

const ListingDetailPage = Loadable(lazy(() => 
  pMinDelay(import('../pages/marketplace/ListingDetailPage'), 1000)
))

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

