import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const BSC_SHAZU_LINK = 'https://shazu.io';
const ETH_SHAZU_LINK = 'https://mint.shazu.io';



// Replace the first index of menuConfig for minting with below

// PREMINT AND MINT CONFIG:
// {
//   title: 'SNC',
//   path: `${ETH_SHAZU_LINK}/mint`
// },

// POSTMINT CONFIG:
// {
//   title: 'SNC',
//   path: `${ETH_SHAZU_LINK}/snc`
// },


const menuConfig = [
  {
    title: 'SNC',
    path: `${ETH_SHAZU_LINK}/#/mint`
  },
  {
    title: 'SNC Bonus',
    path: `${ETH_SHAZU_LINK}/#/sncbonus`
  },
  {
    title: 'Story',
    path: `/story`
  },
  {
    title: 'Token',
    path: `/token`
  },
  {
    title: 'Airdrop',
    path: `/airdrop`
  },
  {
    title: 'Dojo',
    path: `/dojo`
  },
  {
    title: 'Game',
    path: `/game`
  },
  {
    title: 'My NFT',
    path: `/mynft`
  },
  {
    title: 'Marketplace',
    path: '/marketplace'
  },
  // {
  //   title: 'Litepaper',
  //   path: `/static/files/Shazu_Litepaper.pdf`,
  // },
];

export default menuConfig;
