// ----------------------------------------------------------------------

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'theme.customShadows.z12',
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: '#1C1C1C',
          border: 'solid 1px #3B3B3B',
        }
      }
    }
  };
}
