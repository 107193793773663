import React, { useEffect } from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';

import { DAppProvider, BSC } from '@usedapp/core';

import { BetaProvider } from "./contexts/BetaAccessContext";

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

const config = {
  readOnlyChainId: chainId,
  readOnlyUrls: {
    [chainId]: process.env.REACT_APP_RPC_URL
  },
  networks: [BSC],
  multicallVersion: 1
};


const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache()
});

// ----------------------------------------------------------------------

export default function App() {
  Aos.init();
  return (
  <ApolloProvider client={client}>
   <DAppProvider config={config}>
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
            <BetaProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
            </BetaProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
    </DAppProvider>
  </ApolloProvider>
  );
}
