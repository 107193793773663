import { 
    Button,  
    Dialog,
    Typography,
    DialogTitle, DialogActions, DialogContent, DialogContentText,
    Stack} 
    from '@mui/material';
import { margin } from '@mui/system';
import { useState, useEffect } from 'react';

// COMPONENTS
import CopyClipboard from '../../components/CopyClipboard';

// HOOKS
import useWallets from '../../hooks/useWallets';

import { useEthers, shortenAddress } from '@usedapp/core';

import { wallets, connectorsByName, connectorsNames } from '../../connectors';
import { ConstructionOutlined } from '@mui/icons-material';
import WalletConnectProvider from '@walletconnect/web3-provider'

const walletChooseButtonStyle = {
    width: '10rem',
    height: '10rem',
    border: 'solid 1px #3B3B3B',
    margin: '0.5rem',
    fontSize: '1.2rem'
}


export default function ConnectWallet(props){


    const { account, activateBrowserWallet, activate, deactivate } = useEthers();

    const { error } = useEthers();

    const [activateError, setActivateError] = useState('');


    const [connectedStatus, setConnectedStatus] = useState(false);
    const [openConnected, setOpenConnected] = useState(false);

    useEffect(() => {
    if (error) {
        setConnectedStatus(false)
        console.log(error)
        setActivateError("Try to connect again");
    }

    if (account) {
        setConnectedStatus(true)
        setActivateError('');
    }

    if (!account && !error) {
        setActivateError('');
    }
    }, [error, account]);

  
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        setActivateError('');
        // To test Unconnected Wallet Dialog
        // setOpenUnconnected(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleCloseUnconnected = () => {
        setOpenUnconnected(false);
    }


    const handleConnectWallet = async (value) => {

        console.log(value)
        if (value == 'metamask') {
            activateBrowserWallet();
        } else if (value == 'walletconnect') {
            const provider = connectorsByName['walletconnect']
            provider.enable()
            activate(provider)
        }

        setActivateError('');
        setOpen(false);
    }
    
    const handleClickOpenConnected = () => { 
        setOpenConnected(true);
    };

    const handleCloseConnected = () => {
        setOpenConnected(false);
    };

    const [selectedWallet, setSelectedWallet] = useState({wallet: '', address:'', btnText: 'Connect Wallet', onClick: handleClickOpen});

    const handleDisconnect = async () => {
        setSelectedWallet({wallet: '', address:'', btnText: 'Connect Wallet', onClick: handleClickOpen});
        await deactivate()
        setOpenConnected(false);
        setConnectedStatus(false);
        setActivateError('');
    }
    
    // Unconnected Dialog
    const [openUnconnected, setOpenUnconnected] = useState(false);

        useEffect(()=>{
            if(connectedStatus === true){
                setSelectedWallet({wallet: 'metamask', address: account, btnText: 'Connected', onClick: handleClickOpenConnected});
            }else if(connectedStatus === false){
                setSelectedWallet({wallet: '', address: '', btnText: 'Connect Wallet', onClick: handleClickOpen });
            }
        }, [connectedStatus]);


    
    
    const connectWalletButtonStyle = {
        border: 'none',
        width: '10rem',
        height: '3rem',
        marginLeft: 'auto',
        borderRadius: '0',
        backgroundColor: connectedStatus ? '#4BFF73' : '#854BFF',
        color: connectedStatus ? 'black' : 'white',
    }
     
      

    return (
        <>
            <Button size={props.size} variant="container" color="purple" style={connectWalletButtonStyle} onClick={selectedWallet.onClick} value='connect'>
                {selectedWallet.btnText} 
                {/* {
                    connectedStatus === true &&
                    <div style={{ display: 'inline-block', width: '0.5rem', height:'0.5rem', marginLeft: '0.5rem', borderRadius:'100%', backgroundColor: '#38FF3F'}} />
                } */}
                
            </Button>

            <Dialog open={open} onClose={() => handleClose()} maxWidth="md">
                <DialogTitle style={{ textAlign: 'center', marginBottom: '1rem', fontSize: '1.4rem' }}>Connect a Wallet</DialogTitle>
                <DialogContent style={{ textAlign: 'center'}} >
                    {wallets.map((wallet, index) => (
                        // <ListItemButton onClick={() => handleConnectWallet(wallets)} key={wallets}>
                        // <ListItemAvatar>
                        //     <Avatar key={wallets} alt="Remy Sharp" src={`/static/wallets/${wallets}.png`} />
                        // </ListItemAvatar>
                        // <ListItemText primary={wallets} />
                        // </ListItemButton>

                        <Button variant='container' style={ walletChooseButtonStyle } key={index} onClick={() => handleConnectWallet(wallet)}>
                            <Stack>
                                <img src={ `/static/wallets/${wallet}.png `} alt='' style={{ width: '5rem', margin: 'auto', marginBottom: '1rem' }} />
                                <Typography variant='body1'>{connectorsNames[wallet]}</Typography>
                            </Stack>
                            
                        </Button>
                    ))}
                </DialogContent>
            </Dialog>

            <Dialog open={openConnected} onClose={handleCloseConnected}>
                <DialogTitle style={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: '2rem' }}>Connected</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Address:<br />{selectedWallet.address}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseConnected} variant='container' >Close</Button>
                <Button size="large" variant="outlined" color="error" onClick={handleDisconnect} autoFocus>
                    Logout
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openUnconnected} onClose={handleCloseUnconnected} maxWidth="xs" >
                <DialogContent style={{ textAlign: 'center', marginTop: '3rem' }} >
                    <Typography variant='h5' style={{ width: '75%', margin: 'auto' }} >Please connect your wallet first!</Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseUnconnected} variant='container' >Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
