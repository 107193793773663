import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, IconButton, Stack, Alert } from '@mui/material';

import { Icon } from '@iconify/react';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import LogoRsp from '../../components/LogoRsp';

import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import ConnectWallet from '../shazu/ConnectWallet';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));


// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
        className="navbar-toolbar"
      >
          <MHidden width="xlDown">
            <Stack direction="column" style={{ width: '100%' }}>
              <Stack direction="row" style={{ padding: '0.5rem 0'}}>
                <Container
                  maxWidth="100vw"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <RouterLink to="/">
                    <LogoRsp />
                  </RouterLink>

                  <Stack  
                    spacing={1.5}
                    direction="row"
                    style={{ marginLeft: 'auto' }}
                    justifyContent={{ xs: 'flex-end', md: 'flex-end' }}
                    sx={{ mt: 1, mb: { xs: 0, md: 0 } }}
                  >
                    <IconButton onClick={(event) => window.open('https://t.me/shazu_io', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                      <TelegramIcon />
                    </IconButton>

                    <IconButton onClick={(event) => window.open('https://twitter.com/shazu_io', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                      <TwitterIcon />
                    </IconButton>

                    <IconButton onClick={(event) => window.open('https://discord.com/invite/shazu', '_blank')} style={{ color: 'white'}} sx={{ p: 1 }}>
                      <img src="/static/SHAZU/icons/discord.svg" style={{ width: '1em' }} alt="" />
                    </IconButton>
                  </Stack>
                </Container>
              </Stack>

              <Stack direction="row" style={{ borderBottom: '1px solid #80808085', transition: 'background-color .2s', borderTop: '1px solid rgba(128, 128, 128, 0.52)', marginTop: '-4px', backgroundColor: isOffset ? 'var(--shazu-black)' : 'transparent'}}>
                <Container
                  maxWidth="100vw"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  style={{ paddingRight: '0' }}
                >
                  <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig}/> 

                  <ConnectWallet />
                </Container>
              </Stack>

              {/* DESKTOP NETWORK ALERT */}

              {/* CONNECT TO ETH ALERT */}
              {/* <Alert variant="filled" severity="warning" style={{ backgroundColor: 'var(--shazu-red)', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                Please connect your wallet to the Ethereum network to access this page's features!
              </Alert> */}

              {/* CONNECT TO BSC ALERT */}
              {/* <Alert variant="filled" severity="warning" style={{ backgroundColor: 'var(--shazu-red)', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                Please connect your wallet to the BSC network to access this page's features!
              </Alert> */}

            </Stack>
          </MHidden>

        
          <MHidden width="xlUp" >
            <Stack direction="column" style={{ width: '100%' }}>
              <Stack direction="row">
                <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig}  />

                <RouterLink to="/" style={{ position: 'absolute', left: '5rem', top: '1.2rem' }} className="navbar-logo" >
                  <LogoRsp />
                </RouterLink>

                <ConnectWallet />
              </Stack>

              {/* MOBILE NETWORK ALERT */}

              {/* CONNECT TO ETH ALERT */}
              {/* <Alert variant="filled" severity="warning" style={{ backgroundColor: 'var(--shazu-red)', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                Please connect your wallet to the Ethereum network to access this page's features!
              </Alert> */}

              {/* CONNECT TO BSC ALERT */}
              {/* <Alert variant="filled" severity="warning" style={{ backgroundColor: 'var(--shazu-red)', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                Please connect your wallet to the BSC network to access this page's features!
              </Alert> */}
            </Stack>
          </MHidden>

      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
