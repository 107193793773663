import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import  { Redirect } from 'react-router-dom'
import BetaAccessPage from '../pages/BetaAccessPage';

// -------------------------------

const initialState = {
    isAccess: false
};

const BetaAccessContext = createContext(initialState);

function BetaProvider({ children }) {
    const [isAccess, setIsAccess] = useState(true);

    const onChangeAccess = (access) => {
        if(access === true){
            setIsAccess(true);
        }

        if(access === false){
            setIsAccess(false);
        }
    };

    if(isAccess) return (
        <BetaAccessContext.Provider
        value={{
            ...isAccess,
            onChangeAccess
        }}
        >
        {children}
        </BetaAccessContext.Provider>
    );

    return (
        <BetaAccessPage onChangeAccess={onChangeAccess} />
    );


}

export { BetaProvider, BetaAccessContext };
