import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery  } from '@mui/material';

// ----------------------------------------------------------------------

LogoRsp.propTypes = {
  sx: PropTypes.object
};

export default function LogoRsp({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const bigdesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("md"));
  const mobile = useMediaQuery(theme.breakpoints.up("sm"));
  const smallMobile = useMediaQuery(theme.breakpoints.up("xs"));

  if(desktop){
    return(
      <Box sx={{ width: 120, ...sx }} >
        <img src='/static/SHAZU/ShazuTextLogo.svg' alt='SHAZU' />
      </Box>
    )
  }

  if(tablet){
    return(
      <Box sx={{ width: 120, ...sx }}>
        <img src='/static/SHAZU/ShazuTextLogo.svg' alt='SHAZU' />
      </Box>
    )
  }

  if(mobile){
    return(
      <Box sx={{ width: 100, ...sx, ml:-2, mt: -1.4 }}>
        <img src='/static/SHAZU/ShazuTextLogo.svg' alt='SHAZU' />
      </Box>
    )
  }

  if(smallMobile){
    return(
      <Box sx={{ width: 80, ...sx, ml:-3, mt: -1 }}>
        <img src='/static/SHAZU/ShazuTextLogo.svg' alt='SHAZU' />
      </Box>
    )
  }

  return (
    <Box sx={{ width: 140, ...sx }}>
      <img src='/static/SHAZU/ShazuTextLogo.svg' alt='SHAZU' />
    </Box>
  );
}
